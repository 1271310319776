import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Context } from './context/LabayhAdmin'
const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { state: { token } } = useContext(Context)
    const companyName = window.location.pathname
    let name = companyName.split('/')[1]
    return (
        <Route {...rest}
            render={props => {
                if (token)
                    return (<Component {...props} />)
                else {
                    return <Redirect to={{
                        pathname: `/${name}`,
                        state: {
                            from: props.location
                        }
                    }} />
                }

            }
            }
        />
    )
}

export default ProtectedRoute