import axios from "axios";

const labahMainAPI = axios.create({
  baseURL:
    // 'https://portal.labayh.net/'
    process.env.REACT_APP_LABAYH_20_API,
});
labahMainAPI.interceptors.request.use(
  async (config) => {
    let token = await localStorage.getItem("ctoken");
    // token = process.env.REACT_APP_API_TOKEN;
    const lang = await localStorage.getItem("lang");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      if (lang) {
        config.headers.lang = `${lang}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

labahMainAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;


    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const oldRefreshToken = await localStorage.getItem("crefreshToken");

        const response = await axios.post(
          `${process.env.REACT_APP_COMPANY_API}/api/company-admin/refresh-token`,
          { refreshToken: oldRefreshToken }
        );
        const { accessToken, refreshToken } = response.data;

        if (accessToken && refreshToken) {
          localStorage.setItem("token", accessToken);
          localStorage.setItem("crefreshToken", refreshToken);
        }

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        return axios(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);


export default labahMainAPI;
